import { ReactComponent as Github } from "./assets/social/github.svg";
import { ReactComponent as Twitter } from "./assets/social/twitter.svg";
import { ReactComponent as Medium } from "./assets/social/medium.svg";
import { ReactComponent as Telegram } from "./assets/social/telegram.svg";

export const headerLinks = [
  { title: "Ecosystem", link: "", idx: "0" },
  { title: "Resources", link: "", idx: "1" },
];

export const innerLinks = [
  { title: "GHOST chain", link: "https://ghostchain.io/", name: 'chain',  idx: "0" },
  { title: "ghostNFT", link: "https://nft.ghostchain.io/", name: 'dao', idx: "1" },
  { title: "ghostAirdrop", link: "https://airdrop.ghostchain.io", name: 'airdrop', idx: "2" }
];

export const innerResources = [
  { title: "Docs", link: "https://docs.nft.ghostchain.io/", name: 'docs',  idx: "0" },
  { title: "Lightpaper", link: "https://blog.ghostchain.io/wp-content/uploads/2022/12/ghostDAO_Litepaper.pdf", name: 'lightpaper', idx: "1" },
  { title: "Blog", link: "https://blog.ghostchain.io", name: 'blog', idx: "2" }
];

export const socialLinks = [
  { Icon: Github, link: "https://github.com/realGhostChain", name: 'github', idx: "0" },
  { Icon: Twitter, link: "https://twitter.com/realGhostChain", name: 'twitter', idx: "1" },
  { Icon: Telegram, link: "https://t.me/realGhostChain", name: 'telegram', idx: "3" },
  { Icon: Medium, link: "https://ghostchain.medium.com/", name: 'medium', idx: "4" }
];

export const modalTitle = "coming soon";

export const modalText = "Join ghostDAO community to learn more about an upcoming DeFi 2.0 cross-chain reserve currency revolution! ghostDAO cross-chain interoperability will be powered by GHOST Chain scheduled to launch in Q3 2023. For more information and integration roadmaps contact us now."
