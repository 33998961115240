import ReactGA from "react-ga4";
import './App.css';

import { useState, useEffect } from "react";

import Canvas from "./containers/Canvas/Canvas";
import Header from "./containers/Header/Header";
import Main from "./containers/Main/Main";
import Modal from "./containers/Modal/Modal";

function App() {
  const [modalOpened, setModalOpened] = useState(false);

  const openModal = () => {
    setModalOpened(true)
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/landing' });
  }, []);

  return (
    <div className="App">
      <Canvas />
      <Modal opened={modalOpened} toggle={setModalOpened} />
      <div className="app-content">
        <Header toggle={openModal} />
        <Main toggle={openModal} />
      </div>
    </div>
  );
}

export default App;
