import { useEffect, useState, Fragment, memo } from 'react';

function Canvas(props) {

  const [err, setErr] = useState(false);

  const webGlInit = (some) => {
	let options = {
    alpha: true,
    stencil: false,
    antialias: false,
    depth: false,
	}
  some.elem = document.querySelector("canvas");
	some.gl = (
	    some.elem.getContext("webgl", options) ||
	    some.elem.getContext("experimental-webgl", options)
	);

  if (!some.gl) return false;
	const vertexShader = some.gl.createShader(some.gl.VERTEX_SHADER);
	some.gl.shaderSource(vertexShader, `
      precision highp float;
	    attribute vec2 aPosition;
	    uniform vec3 uHSV;
	    uniform vec2 uResolution;
	    varying vec4 vColor;
	    vec3 hsv2rgb(vec3 c) {
	    vec4 K = vec4(1.0, 3.0, 1.9, 3.0);
		  vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
		  return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
            }
	    void main() {
	        gl_PointSize = 1.0;
		gl_Position = vec4(
		    ( aPosition.x / uResolution.x * 2.0) - 1.0,
		    (-aPosition.y / uResolution.y * 2.0) + 1.0,
		    0.0,
		    1.0
		);
		vColor = vec4(hsv2rgb(uHSV), 1.0);
	     }
        `);
	some.gl.compileShader(vertexShader);
	const fragmentShader = some.gl.createShader(some.gl.FRAGMENT_SHADER);;
	some.gl.shaderSource(fragmentShader, `
	    precision highp float;
	    varying vec4 vColor;
	    void main() {
	        gl_FragColor = vColor;
	    }
        `);
	some.gl.compileShader(fragmentShader);
        some.program = some.gl.createProgram();
        some.gl.attachShader(some.program, vertexShader);
        some.gl.attachShader(some.program, fragmentShader);
        some.gl.linkProgram(some.program);
        some.gl.useProgram(some.program);
        some.aPosition = some.gl.getAttribLocation(some.program, "aPosition");
        some.gl.enableVertexAttribArray(some.aPosition);
        some.positionBuffer = some.gl.createBuffer();
        some.uHSV = some.gl.getUniformLocation(some.program, "uHSV");
        some.gl.enableVertexAttribArray(some.uHSV);
        some = webGlResize(some);
        window.addEventListener("resize", () => {some = webGlResize(some)}, false);
	return some;
    }

    const webGlResize = (some) => {
        some.width = some.elem.width = some.elem.offsetWidth;
        some.height = some.elem.height = some.elem.offsetHeight;
        const uResolution = some.gl.getUniformLocation(some.program, "uResolution");
        some.gl.enableVertexAttribArray(uResolution);
        some.gl.uniform2f(uResolution, some.width, some.height);
        some.gl.viewport(0, 0,
            some.gl.drawingBufferWidth,
            some.gl.drawingBufferHeight
        );
	return some;
    }

    const webGlBlend = (some, s, d) => {
        some.gl.blendFunc(s, d);
	some.gl.enable(some.gl.BLEND);
    }

    const webGlCreateBuffer = (some, len) => {
        some.num = len;
	some.bufferLine = new Float32Array(len * 2);
	return some;
    }

    const webGlBeginPath = (some) => {
    	some.k = 0;
	return some;
    }

    const webGlStrokeStyle = (some, h, s, v) => {
    	some.gl.uniform3f(some.uHSV, h, s, v);
	return some;
    }

    const webGlLineTo = (some, x, y) => {
    	some.bufferLine[some.k++] = x;
	some.bufferLine[some.k++] = y;
	return some;
    }

    const webGlStroke = (some) => {
        some.gl.bindBuffer(some.gl.ARRAY_BUFFER, some.positionBuffer);
        some.gl.vertexAttribPointer(some.aPosition, 2, some.gl.FLOAT, false, 0, 0);
        some.gl.bufferData(
            some.gl.ARRAY_BUFFER,
            some.bufferLine,
            some.gl.DYNAMIC_DRAW
        );
        some.gl.drawArrays(some.gl.LINE_STRIP, 0, some.num);
	return some;
    }

    const pelinInit = (some) => {
        some.p = new Uint8Array(512);
        const p = new Uint8Array(256);
        for (let i = 0; i < 256; i++) p[i] = i;
        for (let i = 255; i > 0; i--) {
            const n = Math.floor((i + 1) * Math.random());
            [p[i], p[n]] = [p[n], p[i]];
        }
        for (let i = 0; i < 512; i++) some.p[i] = p[i & 255];
	return some;
    }

    const perlinFade = (t) => {
        return t * t * t * (t * (t * 6 - 15) + 10);
    }

    const perlinLerp = (t, a, b) => {
        return a + t * (b - a);
    }

    const perlinGrad = (hash, x, y, z) => {
        const h = hash & 15;
        const u = h < 8 ? x : y;
        const v = h < 4 ? y : h === 12 || h === 14 ? x : z;
        return ((h & 1) === 0 ? u : -u) + ((h & 2) === 0 ? v : -v);
    }

    const perlinNoise = (some, xi, yi, zi) => {
        const X = Math.floor(xi) & 255;
        const Y = Math.floor(yi) & 255;
        const Z = Math.floor(zi) & 255;
        const x = xi - Math.floor(xi);
        const y = yi - Math.floor(yi);
        const z = zi - Math.floor(zi);
        const u = perlinFade(x);
        const v = perlinFade(y);
        const w = perlinFade(z);
        const A = some.p[X] + Y;
        const AA = some.p[A] + Z;
        const AB = some.p[A + 1] + Z;
        const B = some.p[X + 1] + Y;
        const BA = some.p[B] + Z;
        const BB = some.p[B + 1] + Z;
        return perlinLerp(
            w,
            perlinLerp(
                v,
                perlinLerp(u, perlinGrad(some.p[AA], x, y, z), perlinGrad(some.p[BA], x - 1, y, z)),
                perlinLerp(u, perlinGrad(some.p[AB], x, y - 1, z), perlinGrad(some.p[BB], x - 1, y - 1, z))
            ),
            perlinLerp(
                v,
                perlinLerp(u, perlinGrad(some.p[AA + 1], x, y, z - 1), perlinGrad(some.p[BA + 1], x - 1, y, z - 1)),
                perlinLerp(
                    u,
                    perlinGrad(some.p[AB + 1], x, y - 1, z - 1),
                    perlinGrad(some.p[BB + 1], x - 1, y - 1, z - 1)
                )
            )
        );
    }

    const run = (canvas, perlin, z) => {
    	const sw = canvas.width / 100;
	const sh = sw * 40;
	for (let i = 0; i < 180; i++) {
	    canvas = webGlBeginPath(canvas);
            canvas = webGlStrokeStyle(canvas, z % 0.5, 0.5, 0.6);
	    for (let j = -50; j < 50; j++) {
	        const h = perlinNoise(perlin, j * 0.05, z - i * 0.01, z);
	        canvas = webGlLineTo(
	            canvas,
		    canvas.width * 0.5 + 0.01 * (i + 180) * j * sw * 0.5,
		    -90 + i + canvas.height * 0.5 + h * sh
		);
	    }
	    canvas = webGlStroke(canvas);
	}
	z += 0.0012;
	requestAnimationFrame(function() {
	    run(canvas, perlin, z);
	});
    }

    // eslint-disable-next-line
    const starter = () => {
	let canvas = {};
	let perlin = {};
        canvas = webGlInit(canvas);
	webGlBlend(canvas, canvas.gl.ONE, canvas.gl.ONE);
	perlin = pelinInit(perlin);
	canvas = webGlCreateBuffer(canvas, 100);
	let z = 0;
	run(canvas, perlin, z);
    }

    useEffect(() => {
    	try {
            starter();
	} catch {
	    setErr(true);
	}
    }, [starter]);

    return (
      <Fragment>
        {err === false ?
          <canvas className="ghost-canvas"></canvas> :
            <Fragment></Fragment>
          }
        </Fragment>
      )
    }

export default memo(Canvas);
