import { useState, Fragment } from "react";
import ReactGA from "react-ga4";

import { ReactComponent as Burger } from "../../assets/burger.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";

import "./Header.css";

import { headerLinks, socialLinks, innerLinks, innerResources } from "../../template.js";

function Header({ toggle }) {
  const [hovered, setHover] = useState(false);
  const [resources, setResources] = useState(false);
  const [opened, setOpen] = useState(false);

  return (
    <div className="header-container">
      <div className="header-container-logo">
        <Logo />
      </div>
      <Burger
        className="header-container-burger"
        onClick={()=> setOpen(true)}
        style={opened ? { zIndex: "0" } : { zIndex: "15" }}
      />

      <div
        className="header-container-side-content"
        style={opened ?
          { height: "70%", transition: "height ease .4s" }
          :
          { height: "0", transition: "height ease .4s" }
        }
      >
        <div
          className="header-container-side-content-cross"
          onClick={()=> setOpen(false)}
          style={opened ?
            { opacity: "1", transition: "opacity ease .3s .3s" }
            :
            { opacity: "0" }
          }
        ></div>
        <NavElements
          className="header-container-side-content-links"
          setHover={setHover}
          setResources={setResources}
          hovered={hovered}
          resources={resources}
          toggle={toggle}
          needed
        />
      <IconElements toggle={toggle} className="header-container-content-socials" />
      </div>


      <div className="header-container-content">
        <NavElements
          className="header-container-content-links"
          setHover={setHover}
          setResources={setResources}
          hovered={hovered}
          resources={resources}
          toggle={toggle}
        />

      <IconElements toggle={toggle} className="header-container-content-socials" />
      </div>
    </div>
  );
}

function IconElements({ className, toggle }) {
  return (
    <div className={className}>
      {socialLinks.map(({ Icon, link, name, idx }) => (
        <Icon
          className="header-container-content-socials-link"
          onClick={() => {ReactGA.send({ hitType: "pageview", page: `/${name}` }); idx === "0" ? toggle() : window.open(link, "_blank")}}
          key={idx}
        />
      ))}
    </div>
  );
}

function NavElements({ className, setHover, setResources, hovered, resources, needed, toggle }) {
  return (
    <div className={className}>
      {headerLinks.map(({ title, link, name, idx }) => (
        <Fragment key={idx}>
          <div
            key={idx}
            className={`${className}-droplink`}
            style={idx === "1" ? { width: "150.49px" } : {}}
            onTouchStart={() => {setResources(idx === "0" ? true : false); setHover(idx === "0" ? true : false)}}
            onMouseEnter={() => {setResources(idx === "1" ? true : false); setHover(idx === "0" ? true : false)}}
            onMouseLeave={() => {setResources(false);setHover(false)}}
          >
            {title}
          </div>
          {idx === "0" ?
            <div
              className={`${className}-dropdown`}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={needed ? {} : hovered ?
                { transition: "height ease .4s, padding ease .4s" }
                :
                { height: "0", padding: "0", transition: "height ease .4s, padding ease .4s" }
              }
            >
              {innerLinks.map(({ title, link, name, idx}) => (
                <div
                  className={`${className}-link`}
                  key={idx}
                  onClick={() => {
                    if (hovered) {
                      ReactGA.send({ hitType: "pageview", page: `/${name}` });
                      window.open(link, '_blank').focus()}
                    }
                  }
                  style={needed ? {} : hovered ?
                    { opacity: "1", transition: "opacity ease .9s, translateY ease .9s" }
                    :
                    {
                      opacity: "0",
                      zIndex: "-1",
                      transform: "translateY(-8vh), opacity ease .9s"
                    }
                  }
                >{title}</div>
              ))}
            </div>
            :
            idx === "1" ?
              <div
                className={`${className}-dropdown-resources`}
                onMouseEnter={() => setResources(true)}
                onMouseLeave={() => setResources(false)}
                style={needed ? {} : resources ?
                  { transition: "height ease .4s, padding ease .4s" }
                  :
                  { height: "0", padding: "0", transition: "height ease .4s, padding ease .4s" }
                }
              >
                {innerResources.map(({ title, link, name, idx}) => (
                  <div
                    className={`${className}-link`}
                    key={idx}
                    onClick={() => {
                      if (resources) {
                        ReactGA.send({ hitType: "pageview", page: `/${name}` });
                        idx === "0" ? toggle() : window.open(link, '_blank').focus()}
                      }
                    }
                    style={needed ? {} : resources ?
                      { opacity: "1", transition: "opacity ease .9s, translateY ease .9s" }
                      :
                      {
                        opacity: "0",
                        zIndex: "-1",
                        transform: "translateY(-8vh), opacity ease .9s"
                      }
                    }
                  >{title}</div>
                ))}
              </div>
            :
            <></>
          }
        </Fragment>
      ))}
    </div>
  );
}

export default Header;
