import "./Modal.css";

import ReactModal from 'react-modal';

import { socialLinks, modalTitle, modalText } from "../../template.js";

function Modal({ opened, toggle }) {

  const closeModal = () => {
    toggle(false);
  };

  return (
    <ReactModal
      isOpen={opened}
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel="Example Modal"
      preventScroll={true}
      style={
        {
          overlay: {
            backgroundColor: "transparent",
            backdropFilter: "blur(1vmax)",
            zIndex: "100"
          },
          content: {
            background: "transparent",
            width: "calc(50vw + 70px)",
            height: "50vh",
            padding: "none",
            left: "calc(50% - 25vw - 35px)",
            top: "calc(50% - 25vh)",
            borderRadius: "2vh",
            border: ".2vh solid #f2e370",
            boxShadow: "0 0 .5vh black"
          }
        }
      }
    >
      <div className="modal-container">
        <div className="modal-container-title">{modalTitle}</div>
        <div className="modal-container-text">{modalText}</div>
        <div className="modal-container-links">
          {socialLinks.map(({ Icon, link, idx }) => (
            <Icon
              className="modal-container-links-link"
              onClick={()=> window.open(link, "_blank")}
              key={idx}
            />
          ))}
        </div>
      </div>
    </ReactModal>
  );
}

export default Modal;
