import "./Button.css";

function Button({ name, width, maxWidth, minWidth, className, action }) {
  return (
    <button
      className={className + " primary-button"}
      style={width ? minWidth ? { width: width, minWidth: minWidth, maxWidth: maxWidth } : { width: width} : {}}
      onClick={action}
    >
      {name}
    </button>
  );
}

export default Button;
