import "./Main.css";

import Button from "../../components/Button/Button.js";

import { ReactComponent as OuterCircle } from "../../assets/OuterCircle.svg";
import { ReactComponent as InnerCircle } from "../../assets/InnerCircle.svg";

function Main({ toggle }) {

  // const prepareValue = (value, decimals = 2, minimumFractionDigits = 2) => {
  //   if (!value) return "0"
  //   const nf = new Intl.NumberFormat('en-US', { style: "decimal", maximumFractionDigits: decimals, minimumFractionDigits: minimumFractionDigits });
  //   const result = nf.format(value) + (value.slice(-1) === "." ? "." : "");
  //   return result;
  // };

  return(
    <div className="main-container">
      {/*<div className="main-container-info-btn">
        <Button name="App" width="65vw" minWidth="200px" action={() => window.open('https://app.dao.ghostchain.io/', '_blank').focus()} />
        <Button name="Docs" width="65vw" minWidth="200px" action={() => window.open('https://app.dao.ghostchain.io/', '_blank').focus()} />
      </div>*/}
      <div className="main-container-image">
        <OuterCircle className="main-container-image-circle" />
        <InnerCircle className="main-container-image-circle-inner" />
      </div>

      <div className="main-container-buttons">
        <Button name="App" width="230px" action={() => toggle()} />
        <Button name="Docs" width="230px" action={() => toggle()} />
      </div>

      <div className="main-container-stats">
        <div className="main-container-stats-unit">
          <div className="main-container-stats-unit-title">Total Staked</div>
          <div className="main-container-stats-unit-value">-</div>
        </div>

        <div className="main-container-stats-unit">
          <div className="main-container-stats-unit-title">Treasury Balance</div>
          <div className="main-container-stats-unit-value">-</div>
        </div>

        <div className="main-container-stats-unit">
          <div className="main-container-stats-unit-title">Current APY</div>
          <div className="main-container-stats-unit-value">-</div>
        </div>
      </div>
    </div>
  );
}

export default Main;
